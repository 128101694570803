export const currencySymbols = {
    AED: 'د.إ',
    AFN: 'Af',
    ALL: 'Lek',
    AMD: 'դ',
    ANG: 'ƒ',
    AOA: 'Kz',
    ARS: '$',
    AUD: 'A$',
    AWG: 'ƒ',
    AZN: '₼',
    BAM: 'KM',
    BBD: '$',
    BDT: '৳',
    BGN: 'лв',
    BHD: '.د.ب', 
    BIF: 'FBu',
    BMD: '$',
    BND: '$',
    BOB: '$b',
    BRL: 'R$',
    BSD: '$',
    BTN: 'Nu.',
    WP: 'P',
    BYR: 'p.',
    BYN: 'Br',
    BZD: 'BZ$',
    CAD: 'C$',
    CDF: 'FC',
    CHF: 'CHF',
    CLF: 'UF',
    CLP: '$',
    CNY: '¥',
    COP: '$',
    CRC: '₡',
    CUP: '⃌',
    CVE: '$', 
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'kr',
    DOP: 'RD$',
    DZD: 'دج',
    EGP: 'E£',
    ETB: 'Br',
    EUR: '€',
    FJD: '$',
    FKP: '£',
    GBP: '£',
    GEL: 'ლ',
    GHS: '¢',
    GIP: '£',
    GMD: 'D', 
    GNF: 'FG',
    GTQ: 'Q',
    GYD: '$',
    HKD: 'Hk$',
    HNL: 'L',
    HRK: 'kn',
    HTG: 'G',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    INR: '₹',
    IQD: 'ع.د',
    IRR: '﷼',
    ISK: 'kr',
    JEP: '£',
    JMD: 'J$',
    JOD: 'JD',
    JPY: '¥',
    KES: 'KSh',
    KGS: 'сом',
    KHR: '៛',
    KMF: 'CF', 
    KPW: '₩',
    KRW: '₩',
    KWD: 'د.ك',
    KYD: '$',
    KZT: '₸',
    LAK: '₭',
    LBP: '£',
    LKR: '₨',
    LRD: '$',
    LSL: 'L',
    LTL: 'Lt',
    LVL: 'Ls',
    LYD: 'ل.د', 
    MAD: 'د.م.',
    MDL: 'L',
    MGA: 'Ar',
    MKD: 'ден',
    MMK: 'K',
    MNT: '₮',
    MOP: 'MOP$', 
    MRO: 'UM', 
    MUR: '₨', 
    MVR: '.ރ',
    MWK: 'MK',
    MXN: '$',
    MYR: 'RM',
    MZN: 'MT',
    NAD: '$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: '₨',
    NZD: 'Nz$',
    OMR: '﷼',
    PAB: 'B/.',
    PEN: 'S/.',
    PGK: 'K',
    PHP: '₱',
    PKR: '₨',
    PLN: 'zł',
    PYG: 'Gs',
    QAR: '﷼',
    RON: 'lei',
    RSD: 'Дин.',
    RUB: '₽',
    RWF: 'ر.س',
    SAR: '﷼',
    SBD: '$',
    SCR: '₨',
    SDG: '£',
    SEK: 'kr',
    SGD: 'Sg$',
    SHP: '£',
    SLL: 'Le', 
    SOS: 'S',
    SRD: '$',
    STD: 'Db',
    SVC: '$',
    SYP: '£',
    SZL: 'L',
    THB: '฿',
    TJS: 'TJS',
    TMT: 'm',
    TND: 'د.ت',
    TOP: 'T$',
    TRY: '₤',
    TTD: '$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    USD: '$',
    UYU: '$U',
    UZS: 'сӯм',
    VEF: 'Bs',
    VND: '₫',
    VUV: 'VT',
    WST: 'WS$',
    XAF: 'FCFA',
    XCD: '$',
    XDR: 'SDR',
    XOF: 'FCFA',
    XPF: 'F',
    YER: '﷼',
    ZAR: 'R',
    ZMK: 'ZK', 
    ZWL: 'Z$',
}