export const getDate = (date, setDate) => {

    const options = {
        era: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    setDate(
        new Date(date).toLocaleString('ru', options),
    );

}