import './scss/app.scss';
import arrowUp from './assets/img/arrow-up-red.svg';
import arrowDown from './assets/img/arrow-down-green.svg';
import { useEffect, useState } from 'react';
// import './data/daily_json.js';
import axios from 'axios';
import { currencySymbols as symbols } from './data/symbols';
import Loader from './components/Loader/Loader.jsx';
import { getDate } from './utils/getDate';
import CurrenciesMenu from './components/CurrenciesMenu';

function App() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [current, setCurrent] = useState({});
    const [currency, setCurrency] = useState('USD');
    const [date, setDate] = useState('');
    const [menu, setMenu] = useState(false);
    const [actual, setActual] = useState(0);
    const [previous, setPrevious] = useState(0);

    const getLocalCurrency = async () => {
        const localCurrency = await localStorage.getItem('currencyCharCode');
        if (localCurrency.length === 3) {
            setCurrency(localCurrency);
        } else {
            setCurrency('USD');
        }
    };

    const currencyData = async () => {
        setLoading(true);

        const response = await axios
            .get('./data/daily_json.json')
            .then((response) => response.data);
        setData(response);
        getLocalCurrency();
        setCurrent(response.Valute[currency]);
        setLoading(false);
    };

    useEffect(() => {
        currencyData();
    }, []);

    useEffect(() => {
        if (loading) return;

        setCurrent(data.Valute[currency]);
        setActual(current.Value.toFixed(2));
        setPrevious(current.Previous.toFixed(2));
    }, [loading, currency, current.Value, current.Previous]);

    useEffect(() => {
        if (loading) return;
        getDate(data.Date, setDate);
    }, [loading]);

    if (loading) return <Loader />;

    return (
        <div className="app">
            <div className="currency__wrapper">
                <div className="currency__bg">
                    <div className="currency__letters">{currency}</div>
                </div>
                <div className="currency">
                    <div
                        className="currency__link"
                        title={'за ' + current.Nominal + ' ' + current.Name}
                        onClick={() => setMenu(1)}
                    >
                        <span className="currency__symbol">
                            {symbols[currency]}
                        </span>
                        {current.Nominal}
                    </div>
                    <div className="currency__date">{date}</div>
                    <div
                        className={
                            'currency__actual' +
                            (actual > previous
                                ? ' currency__up'
                                : ' currency__down')
                        }
                    >
                        {actual}
                    </div>
                    <div className="currency__arrow">
                        <img
                            src={actual > previous ? arrowUp : arrowDown}
                            alt=""
                        />
                    </div>
                    <div
                        className={
                            'currency__previous' +
                            (actual < previous
                                ? ' currency__up'
                                : ' currency__down')
                        }
                    >
                        {previous}
                    </div>
                </div>
            </div>

            <CurrenciesMenu
                setMenu={setMenu}
                menu={menu}
                data={data.Valute}
                currency={currency}
                setCurrency={setCurrency}
            />
        </div>
    );
}

export default App;
