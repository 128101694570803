import React from 'react';

const CurrenciesMenu = ({ setMenu, menu, data, currency, setCurrency }) => {


    const curencyClickHandler = (CharCode) => {
        setCurrency(CharCode);
        localStorage.setItem('currencyCharCode', CharCode);
    };

    if (!menu) return;

    const currencies = Object.values(data).sort((a, b) => a.Name.localeCompare(b.Name));


    return (
        <div div className="names" onClick={() => setMenu(false)}>
            <div className='names__list' onClick={e => e.stopPropagation()}>
                <div className="names__close__wrapper">
                    <div className='names__close' onClick={() => setMenu(false)}></div>
                </div>

                {currencies.map(item => <div key={item.ID}
                    className={'names__item' + (item.CharCode === currency ? ' names__item__current' : '')}
                    onClick={() => curencyClickHandler(item.CharCode)}
                >{item.Nominal} {item.Name}</div>)}

            </div>
        </ div>
    );
};

export default CurrenciesMenu;